import { render, staticRenderFns } from "./CriarApresentante.vue?vue&type=template&id=1a03f7ce&scoped=true"
import script from "./CriarApresentante.vue?vue&type=script&lang=js"
export * from "./CriarApresentante.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a03f7ce",
  null
  
)

export default component.exports